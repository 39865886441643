/**
 * This snippet is to unregister the service workers that were previously
 * registered, but should be safe to remove after a few weeks.
 * So if you're reading this in 2025 or later, please remove it ;)
 */
// @ts-ignore
if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}
